import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { graphql } from 'gatsby'
import DataViewer from '~utils/DataViewer'
import PostIndex from '~components/PostIndex'
import Seo from '~components/Seo'

const Projects = ({ data }) => {
	const page = data?.sanityProjects
	return (
		<>	
			<Seo 
				title={page.title}
				metaTitle={page.seo?.metaTitle}
				description={page.seo?.metaDescription}
				image={page.seo?.socialImage}
			/>
			<PostIndex posts={data.projects?.nodes} filters={page.tags} title={page.title} rows={3}/>
			<DataViewer data={data} name="page"/>
		</>
	)
}

Projects.propTypes = {
	data: PropTypes.object,
}

export const query = graphql`
	query ProjectsQuery {
		sanityProjects{
			title {
				...multiString
			}
			slug {
				current
			}
			tags {
				title {
				...multiString
				}
				slug {
					current
				}
			}
			seo {
				...seo
			}
		}
		projects: allSanityProject{
			nodes {
				...projectThumb
			}
		}
	}
`

export default Projects